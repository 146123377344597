<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Kullanıcı Hareketleri"
            icon="mdi-account-reactivate"
            @clear-filters="clearFilters()"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'logs.action.show',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.loggable="{ item }">
          <div v-if="item.loggable !== null">
            <a
              v-if="item.loggable_type === 'App\\Models\\Block'"
              @click.prevent="gotoBlock(item)"
            >
              {{ item.loggable.name }}
            </a>

            <a
              v-else-if="item.loggable_type === 'App\\Models\\HouseType'"
              @click.prevent="gotoHouseType(item)"
            >
              {{ item.loggable.name }}
            </a>

            <a
              href="#"
              v-else-if="item.loggable_type === 'App\\Models\\Collecting'"
              @click.prevent="gotoCollecting(item)"
            >
              {{ item.loggable.description }}
            </a>

            <a
              href="#"
              v-else-if="item.loggable_type === 'App\\Models\\Debt'"
              @click.prevent="gotoDebt(item)"
            >
              {{ item.loggable_description }}
            </a>

            <router-link v-else :to="loadRoute(item)">
              {{ getLoggable(item) }}
            </router-link>
          </div>
          <div v-else>
            {{ getLoggable(item) }}
          </div>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.action_type="{ item }">
          {{ item.action_type }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.action_on="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir TYA seçin.
    </v-card-text>
    <BlockForm ref="blockForm" />
    <HouseTypeForm ref="houseTypeForm" />
    <CollectingDetail ref="collectingDetailForm" />
    <DebtDetail ref="debtDetail" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import BlockForm from "@/view/content/clusters/BlockForm";
import { CollectingDetail, DebtDetail } from "@/view/pages/income/forms";
import HouseTypeForm from "@/view/content/clusters/HouseTypeForm";
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasPermissions } from "@/view/mixins";

export default {
  mixins: [hasDataTable, hasPermissions],
  components: {
    BlockForm,
    CollectingDetail,
    DebtDetail,
    HouseTypeForm,
  },
  computed: {
    ...mapGetters(["clusterId", "actionTypeList"]),
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }, 250),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  data() {
    return {
      options: {
        sortBy: ["action_on"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Adı Soyadı",
          value: "user",
          searchable: "text",
          width: "175px",
        },
        {
          text: "İşlem",
          value: "action_type",
          sortable: false,
          searchable: "multiselect",
          options: () => this.actionTypeList,
          width: "100px",
        },
        {
          text: "İşlem Yapılan",
          value: "loggable",
          searchable: "text",
          width: "200px",
        },
        {
          text: "Tarih-Saat",
          value: "action_on",
          searchable: "date",
          width: "250px",
        },
      ],
    };
  },
  methods: {
    gotoBlock(item) {
      this.$refs.blockForm.show(item.loggable.id);
    },
    gotoHouseType(item) {
      this.$refs.houseTypeForm.show(item.loggable.id);
    },
    gotoCollecting(item) {
      this.$refs.collectingDetailForm.show(item.loggable.id);
    },
    gotoDebt(item) {
      this.$refs.debtDetail.show(item.loggable.id);
    },
    loadRoute(item) {
      switch (item.loggable_type) {
        case "App\\Models\\House":
          return {
            name: "definitions.houses.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\BankAccount":
          return {
            name: "definitions.bank-accounts.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Cluster":
          return {
            name: "definitions.clusters.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Company":
          return {
            name: "definitions.companies.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Provider":
          return {
            name: "definitions.cluster-providers.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Resident":
          return {
            name: "definitions.residents.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Income\\PeriodicalAssessment":
          return {
            name: "definitions.periodical-assessments.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Income\\OtherIncome":
          return {
            name: "incomes.other.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Income\\OtherIncomePayment":
          return {
            name: "incomes.other.show",
            params: { id: item.loggable.other_income_id },
          };

        case "App\\Models\\Safe":
          return {
            name: "definitions.safes.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Assessment":
          if (item.loggable.assessment_type_id === 1) {
            return {
              name: "assessments.general.show",
              params: { id: item.loggable.id },
            };
          } else if (item.loggable.assessment_type_id === 2) {
            return {
              name: "assessments.house-type.show",
              params: { id: item.loggable.id },
            };
          } else if (item.loggable.assessment_type_id === 3) {
            return {
              name: "assessments.free.show",
              params: { id: item.loggable.id },
            };
          }

          return {
            name: "assessments.all.list",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Type\\ExpenseType":
          return {
            name: "other-definitions.expense-types.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Expense\\Invoice":
          return {
            name: "expenses.invoices.show",
            params: { id: item.loggable.id },
          };

        case "App\\Models\\Expense\\InvoicePayment":
          return {
            name: "expenses.invoices.show",
            params: { id: item.loggable.invoice_id },
            hash: "#payments",
          };

        case "App\\Models\\Type\\IncomeType":
          return {
            name: "other-definitions.income-types.show",
            params: { id: item.loggable.id },
          };
      }
    },
    getLoggable(item) {
      if (item.loggable_type === "App\\Models\\House") {
        if (item.loggable !== null) {
          return item.loggable.door_number == null
            ? "Bağımsız Bölüm"
            : item.loggable.door_number;
        }
        return "Bağımsız Bölüm";
      }
      if (item.loggable_type === "App\\Models\\Provider") {
        if (item.loggable !== null) {
          return item.loggable.name == null ? "Tedarikçi" : item.loggable.name;
        }
        return "Tedarikçi";
      }
      if (item.loggable_type === "App\\Models\\Income\\OtherIncome") {
        if (item.loggable !== null) {
          return item.loggable.description == null
            ? "Diğer Gelir"
            : item.loggable.description;
        }
        return "Diğer Gelir";
      }
      if (item.loggable_type === "App\\Models\\Income\\OtherIncomePayment") {
        if (item.loggable !== null) {
          return item.loggable_description == null
            ? "Diğer Gelir Ödeme"
            : item.loggable_description;
        }
        return "Diğer Gelir Ödeme";
      }
      if (item.loggable_type === "App\\Models\\HouseType") {
        if (item.loggable !== null) {
          return item.loggable.name == null
            ? "Bağımsız Bölüm Tipi"
            : item.loggable.name;
        }
        return "Bağımsız Bölüm Tipi";
      }
      if (item.loggable_type === "App\\Models\\Cluster") {
        if (item.loggable !== null) {
          return item.loggable.name == null
            ? "Toplu Yaşam Alanı"
            : item.loggable.name;
        }
        return "Toplu Yaşam Alanı";
      }
      if (item.loggable_type === "App\\Models\\Collecting") {
        if (item.loggable !== null) {
          return item.loggable.description == null
            ? "Tahsilat"
            : item.loggable.description;
        }
        return "Tahsilat";
      }
      if (item.loggable_type === "App\\Models\\Debt") {
        if (item.loggable !== null) {
          return item.loggable_description == null
            ? "Borç"
            : item.loggable_description;
        }
        return "Borç";
      }
      if (item.loggable_type === "App\\Models\\BankAccount") {
        if (item.loggable !== null) {
          return item.loggable.name == null ? "Banka" : item.loggable.name;
        }
        return "Banka";
      }
      if (item.loggable_type === "App\\Models\\Resident") {
        if (item.loggable !== null) {
          return item.loggable.name == null ? "Sakin" : item.loggable.name;
        }
        return "Sakin";
      }
      if (item.loggable_type === "App\\Models\\Income\\PeriodicalAssessment") {
        if (item.loggable !== null) {
          return item.loggable.description == null
            ? "Periyodik Borçlandırma"
            : item.loggable.description;
        }
        return "Periyodik Borçlandırma";
      }
      if (item.loggable_type === "App\\Models\\Safe") {
        if (item.loggable !== null) {
          return item.loggable.name == null ? "Kasa" : item.loggable.name;
        }
        return "Kasa";
      }
      if (item.loggable_type === "App\\Models\\Assessment") {
        if (item.loggable !== null) {
          return item.loggable.description == null
            ? "Tahakkuk"
            : item.loggable.description;
        }
        return "Tahakkuk";
      }
      if (item.loggable_type === "App\\Models\\Type\\ExpenseType") {
        if (item.loggable !== null) {
          return item.loggable.name == null
            ? "Gider Türleri"
            : item.loggable.name;
        }
        return "Gider Türleri";
      }
      if (item.loggable_type === "App\\Models\\Expense\\Invoice") {
        if (item.loggable !== null) {
          return item.loggable.description == null
            ? "Gider Faturası"
            : item.loggable.description;
        }
        return "Gider Faturası";
      }
      if (item.loggable_type === "App\\Models\\Expense\\InvoicePayment") {
        if (item.loggable !== null) {
          return item.loggable.description == null
            ? "Gider Faturası Ödeme"
            : item.loggable.description;
        }
        return "Gider Faturası Ödeme";
      }
      if (item.loggable_type === "App\\Models\\Company") {
        if (item.loggable !== null) {
          return item.loggable.name == null
            ? "Yönetim Şirketi"
            : item.loggable.name;
        }
        return "Yönetim Şirketi";
      }
      if (item.loggable_type === "App\\Models\\Type\\IncomeType") {
        if (item.loggable !== null) {
          return item.loggable.name == null ? "Gelir Türü" : item.loggable.name;
        }
        return "Gelir Türü";
      }
      if (item.loggable_type === "App\\Models\\Block") {
        if (item.loggable !== null) {
          return item.loggable.name == null ? "Blok" : item.loggable.name;
        }
        return "Blok";
      }
    },
    clearFilters() {
      this.$refs.headSearch.reset();
      this.filterdate = null;
      this.search.query = "";
      this.search.loggable = "";
      this.search.user = "";
      this.search.action_on = {
        min: undefined,
        max: undefined,
      };
      this.loadList();
    },
    loadList() {
      this.selectedItems = [];
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`clusters/${this.clusterId}/action-logs`, { params })
        .then((response) => {
          this.list = response.data.data;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "logs.action.show",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
  },
};
</script>
